<template>
  <div>
    <Splide :options="{
                        rewind: true,
                        gap: (!$isMobile() ? '2em' :'0em'),
                        paddingRight: '5rem',
                        perPage: ($isMobile() ? 1 :3),
                        arrows : !$isMobile(),
                        pagination: (filterMovies.length > 1 ? true : false)}">
      <SplideSlide class="movie-style pb-4" v-for="(mov,i) in filterMovies"
          v-bind:key="i">
          <div
              class="img"
              :style="genMovieStyle(mov)"
          >
            <img @click="openVideo(mov)" class="play-icon-3" :style="verticalPosition" src="@/assets/img/play_icon.png">
<!--          </div>-->
<!--          <div class="meta mt-2 text-center" v-bind:class="{'hover': showMeta(i)}">-->
<!--            <h6 class="mt-3 mb-2">{{mov.title}}</h6>-->
<!--            <span class="small ">{{ genre(mov.genres) }}{{ mov.duration.toDurationLength() }}{{ country(mov.country) }}</span>-->
<!--            <br>-->

          </div>
        <router-link class="a-no-decoration" :to="{name: 'movie', params: {slug: mov.slug}}">
          <span class="font-weight-bold pt-3 pb-1 d-block text-white ">{{mov.title}}</span>
          <p class="small text-muted" v-html="mov.short_description"></p>
        </router-link>
        <router-link class="text-muted text-capitalize" :to="{name: 'movie', params: {slug: mov.slug}}">
          {{$t("Ver Más")}}
        </router-link>
      </SplideSlide>
    </Splide>
  </div>
        <!--    <div-->
<!--        class="container-fluid  mb-5 movie-carousel"-->
<!--        v-if="movies.length > 0"-->
<!--        v-bind:class="{'top-marker':hasTitle}"-->
<!--    >-->

<!--      <div class="row justify-content-center " v-if="hasTitle">-->
<!--        <div class="col-6">-->
<!--          <h3 class="text-center" v-html="title"></h3>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="row justify-content-center text-center anti-gutter">-->
<!--        <div-->
<!--            class="col p-0 movie-style"-->
<!--            v-for="(mov,i) in filterMovies"-->
<!--            v-bind:key="i"-->
<!--        >-->
<!--          <div-->
<!--              class="img"-->
<!--              :style="genMovieStyle(mov)"-->
<!--          >-->
<!--            <img @click="openVideo(mov)" class="play-icon cursor-pointer" src="@/assets/img/play_icon.png">-->
<!--          </div>-->
<!--          <div class="meta mt-2" v-bind:class="{'hover': showMeta(i)}">-->
<!--            {{ genre(mov.genres) }}{{ mov.duration.toDurationLength() }}{{ country(mov.country) }}-->
<!--            <br>-->
<!--            <router-link class="read-more" :to="{name: 'movie', params: {slug: mov.slug}}">-->
<!--              ver mas-->
<!--            </router-link>-->
<!--          </div>-->

<!--        </div>-->

<!--      </div>-->
<!--      <div class="row controller anti-gutter">-->
<!--        <div class="col">-->
<!--          <i class="bi bi-chevron-compact-left float-left"></i>-->
<!--          <i class="bi bi-chevron-compact-right float-right"></i>-->
<!--        </div>-->
<!--      </div>-->

<!--    </div>-->
</template>

<script>

import '@splidejs/splide/dist/css/splide.min.css';
import { Splide, SplideSlide } from '@splidejs/vue-splide';


export default {
  name: "MovieCarousel",
  components: {
    Splide,
    SplideSlide,
  },
  props:{
    title:{
      type: String,
      default: function(){
        return ""
      }
    },
    movies:{
      type: Array,
      default: function(){
        return [
            // {
            //   id: Number,
            //   slug: String,
            //   thumbnail: String,
            //   video: String,
            //   title: String,
            //   duration: String,
            //   country: String,
            //   genre: String
            // }
        ]
      }
    }
  },
  computed:{
    filterMovies: function(){
      return this.movies;
//      return this.movies.slice(0,5);
    },
    hasTitle: function(){
      return this.title !== '';
    },
    verticalPosition(){
       return {
         paddingTop: (this.$isMobile() ? '20vw' : "")
       }
    }
  },
  methods:{
    country: function(country){
      return (country ? ' | '+country.name : '' )
    },
    genre: function(genres){
      return (genres.length > 0 ? genres[0].name+' | ' : '' )
    },
    showMeta: function(i){
      if(this.movies.length >= 5){
        return i === 2
      }
      if(this.movies.length > 2){
        return i === 1
      }
      return i === 0
    },
    genMovieStyle: function(mov){
      return {
        background: 'url('+mov.screenshot+') no-repeat center center',
        backgroundSize: 'cover',
        height: (this.$isMobile() ? '56vw' : "")
      }
    }
  }
}
</script>

<style lang="scss">
@import "src/assets/styles/colors";
.movie-carousel{
  .movie-style:hover {
    &:last-child{
      transform: scale(1.2)  translate(-10%,0);
    }
  }

  .controller{
    padding: 0;
    margin-top: -10em;
    margin-bottom: 7em;
    pointer-events: none;
    i{
      &:hover{
        filter: drop-shadow(0px 0px 2px $light-blue);
        transform: scale(1.5);
      }
      transition: all 500ms;
      font-size: 3em;
      position: relative;
      pointer-events: all;
      cursor: pointer;
      z-index: 98;
      &.float-left{
        margin-left: -.5em;
      }
      &.float-right{
        margin-right: -.5em;
      }
    }
  }


  //.movie-style:hover + .movie-style + .movie-style {
  //  transform: scale(0.9);
  //}
  //.movie-style:hover + .movie-style + .movie-style + .movie-style{
  //  transform: scale(0.9);
  //}
  .movie-style{

    max-width: 30vw;
    transition: transform 500ms;

    .img{
      border: 3px solid $dark-blue;
      border-radius: 7px;
    }
    &:hover{
      transform: scale(1.25);
      transition: transform 300ms ease-in-out;
      z-index: 3;
      &:first-child{
        transform: scale(1.2) translate(21px, 0px);
      }
      .meta{
        opacity: 1;
      }
    }



    .meta{
      font-size: .8em;
      transition: opacity 500ms;
      opacity: 0.1;
      &.hover{
        //opacity: 1;
      }
    }
    .play-icon{
      width: 3em;
      margin-top: 3em;
      margin-bottom: 3em;
    }
  }
}
.play-icon-3{
  margin: auto;
  display: block;
  padding: 5vw 0;
  cursor: pointer;
}
</style>